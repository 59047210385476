import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RESPONSE } from '../../consts/request-response.const';
import { HomeRestService } from '../rest-api/home-rest.service';
import { IframeService } from '../services/iframe.service';
import { CACHE_RESPONSE_HEADER_NAME } from './webrock-cache.guard';

@Injectable({
    providedIn: 'root',
})
export class IframeGuard implements CanActivate {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _response = inject(RESPONSE, { optional: true });
    private readonly _homeRestService = inject(HomeRestService);
    private readonly _iframeService = inject(IframeService);

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const { forIFrame, shopMode, primaryColor, activeBtnTxtColor, footerVisible } = route.queryParams;
        this._iframeService.setIframe(forIFrame, shopMode, primaryColor, activeBtnTxtColor, footerVisible);
        if (isPlatformServer(this._platformId) && forIFrame) {
            const path = state.url.split('?')[0];
            return this._homeRestService.checkDomainIsEnabledForIframe(path, forIFrame).pipe(
                map((res: boolean) => {
                    if (res) {
                        this._response?.setHeader('Content-Security-Policy', `frame-ancestors 'self' ${forIFrame}`);
                    } else {
                        this._response?.setHeader('Content-Security-Policy', "frame-ancestors 'none'");
                        this._response?.setHeader(CACHE_RESPONSE_HEADER_NAME, 'no-store');
                    }

                    return true;
                }),
            );
        } else {
            return true;
        }
    }
}
