import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MemoryCache } from 'memory-cache-node';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CACHE_DURATION } from './config/rest.model';
import { CategoryMapItem } from './models/category-map-item';

const CACHE = new MemoryCache<string, object>(1, 10);

@Injectable({
    providedIn: 'root',
})
export class CategoryRedirectRestService {
    private readonly _httpClient = inject(HttpClient);

    private _portalApi = `${environment.portalApi}`;

    public get(key: string): Observable<CategoryMapItem> {
        return this._getCategoryMap().pipe(map((categoriesDictionary) => categoriesDictionary[key]));
    }

    private _getCategoryMap(): Observable<{ [key: string]: CategoryMapItem }> {
        if (CACHE.hasItem('data')) {
            return new Observable((observer) => {
                observer.next(CACHE.retrieveItemValue('data') as { [key: string]: CategoryMapItem });
                observer.complete();
            });
        }

        return this._fetchCategoryMap();
    }

    private _fetchCategoryMap(): Observable<{ [key: string]: CategoryMapItem }> {
        const path = `${environment.cacheBlobUrl}/categories.json`;

        return this._httpClient.get<CategoryMapItem[]>(path).pipe(
            catchError((err) => of([])),
            map((res: CategoryMapItem[]) => {
                const dictionary: { [key: string]: CategoryMapItem } = {};

                res.forEach((categoryItem: CategoryMapItem) => {
                    const key = this._getCacheKey(categoryItem);
                    dictionary[key] = categoryItem;
                });

                CACHE.storeExpiringItem('data', dictionary, CACHE_DURATION);
                return CACHE.retrieveItemValue('data') as { [key: string]: CategoryMapItem };
            }),
        );
    }

    private _getCacheKey(categoryItem: CategoryMapItem): string {
        const key = categoryItem.category;

        if (categoryItem.subcategory) {
            return `${key}_${categoryItem.subcategory}`;
        }

        return key;
    }
}
