import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SsrCacheService {
    private _url: string | undefined;
    private _cacheEnabled: boolean = true;

    get cacheEnabled(): boolean {
        return this._cacheEnabled;
    }

    setCacheEnabled(url: string) {
        if (this._url) {
            this._cacheEnabled = false;
        }

        this._url = url;
    }
}
