import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
    selector: 'eb-page-loading-spinner',
    standalone: true,
    imports: [CommonModule, SvgIconComponent],
    templateUrl: './page-loading-spinner.component.html',
    styleUrl: './page-loading-spinner.component.less',
})
export class PageLoadingSpinnerComponent {}
