import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { CookiesPolicyDialogComponent } from './cookies-policy-dialog/cookies-policy-dialog.component';

@NgModule({
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'cookies-policy', alias: 'cookiesPolicy' },
        },
    ],
    imports: [CommonModule, TranslocoModule, UiButtonsModule],
    declarations: [CookiesPolicyDialogComponent],
    exports: [CookiesPolicyDialogComponent],
})
export class CookiesPolicyModule {}
