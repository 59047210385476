<div class="eb-cookies-policy-dialog__wrapper fixed left-0 bottom-0 flex w-screen h-screen sm:p-" [ngClass]="iframe ? 'align-items-start' : 'align-items-end'">
  <div class="eb-cookies-policy-dialog p-4 md:p-6">
    <ng-container *ngIf="isMobile; else desktopCookiesPolicy">
      <div class="eb-cookies-policy-dialog__text-container overflow-y-auto pr-3">
        <p class="h5 mb-3 eb-cookies-policy-dialog__title" [innerHtml]="'cookiesPolicy.header' | transloco"></p>
        <p class="eb-size--tiny mb-3 eb-cookies-policy-dialog__text-mobile" [innerHtml]="'cookiesPolicy.text' | transloco"></p>
      </div>
    </ng-container>
    <div class="eb-cookies-policy-dialog__buttons md:flex md:flex-column md:flex-wrap md:justify-content-center md:align-content-end">
      <button class="mt-3 lg:mt-0 lg:mr-3" eb-button ebType="primary" (click)="acceptCookiesPolicy()" [innerHtml]="'cookiesPolicy.buttonText' | transloco"></button>
      <button class="mt-3 lg:mt-2 lg:mr-3" eb-button ebType="light" (click)="rejectCookiesPolicy()" [innerHtml]="'cookiesPolicy.rejectText' | transloco"></button>
    </div>
  </div>
</div>

<ng-template #desktopCookiesPolicy>
  <p class="h5 mb-3 eb-cookies-policy-dialog__title" [innerHtml]="'cookiesPolicy.header' | transloco"></p>
  <p class="eb-size--tiny mb-3 eb-cookies-policy-dialog__text-desktop" [innerHtml]="'cookiesPolicy.textColumnOne' | transloco"></p>
  <p class="eb-size--tiny mb-3 eb-cookies-policy-dialog__text-desktop" [innerHtml]="'cookiesPolicy.textColumnTwo' | transloco"></p>
</ng-template>
