import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RESPONSE } from '../../consts/request-response.const';
import { IRouteData } from './route-data.interface';

@Injectable({
    providedIn: 'root',
})
export class WebrockCacheGuard implements CanActivate {
    private readonly _router = inject(Router);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _response = inject(RESPONSE, { optional: true });

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (isPlatformServer(this._platformId)) {
            const currentRoute = this._getLastDesendantRouteSnapshot(state.root);
            const currentRouteData: IRouteData = currentRoute.data;
            const cacheTime = currentRouteData.cacheTime || DEFAULT_CACHE_TIME;
            if (cacheTime === CacheTime.NONE) {
                this._response?.removeHeader(CACHE_RESPONSE_HEADER_NAME);
            } else {
                this._response?.setHeader(CACHE_RESPONSE_HEADER_NAME, `public, max-age=${cacheTime}`);
            }
        }
        return of(true);
    }

    private _getLastDesendantRouteSnapshot(root: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
        let route = root;
        let firstChild = route.children.length ? route.children[0] : null;
        while (firstChild) {
            route = firstChild;
            firstChild = route.children.length ? route.children[0] : null;
        }

        return route;
    }
}

export enum CacheTime {
    NONE = -1,
    SOCOND_1 = 1,
    MINUTE_1 = 60,
    MINUTE_3 = 180,
    MINUTE_10 = 600,
    MINUTE_15 = 900,
    WEEK_1 = 604800,
    YEAR_1 = 31536000,
}

export const DEFAULT_CACHE_TIME = CacheTime.MINUTE_3;
export const CACHE_RESPONSE_HEADER_NAME = 'Cache-Control';
