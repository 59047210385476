import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IOldSearchQueryParams } from '../infrastructure/search-page.guard';
import { LandingPageLocationType } from '../rest-api/models/landing-page-location-exit.model';
import { BaseRedirectService } from './base-redirect.service';

@Injectable({
    providedIn: 'root',
})
export class SearchPageRedirectService extends BaseRedirectService {
    constructor() {
        super();
    }

    public shouldRedirect(oldSearchQueryParams: IOldSearchQueryParams): Observable<string | null> {
        if (!oldSearchQueryParams.k) {
            return of(null);
        }

        const searchParams = this.mapToSearchParams(oldSearchQueryParams);
        if (Array.from(Object.keys(searchParams)).length > 0) {
            return of(this.mapToUrl(LandingPageLocationType.NOT_EXIST, searchParams));
        }

        return of(null);
    }
}
