import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IOldSearchQueryParams } from '../infrastructure/search-page.guard';
import { LandingLocationPageRestService } from '../rest-api/landing-location-page-rest.service';
import { LandingPageLocationType } from '../rest-api/models/landing-page-location-exit.model';
import { BaseRedirectService } from './base-redirect.service';

@Injectable({
    providedIn: 'root',
})
export class LandingPageRedirectService extends BaseRedirectService {
    private readonly _landingLocationPageRestService = inject(LandingLocationPageRestService);

    public shouldRedirect(oldSearchQueryParams: IOldSearchQueryParams): Observable<string | null> {
        if (!oldSearchQueryParams.r) {
            return of(null);
        }

        const searchParams = this.mapToSearchParams(oldSearchQueryParams);
        const landingLocationLPageExistRequest = this.mapToExistRequest(searchParams);

        return this._landingLocationPageRestService.exist(landingLocationLPageExistRequest).pipe(
            catchError(() => of(null)),
            map((response: LandingPageLocationType | null) => this.mapToUrl(response, searchParams)),
        );
    }
}
