import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { CategoryRedirectRestService } from '../rest-api/category-redirect-rest.service';
import { CategoryMapItem } from '../rest-api/models/category-map-item';
import { BaseRedirectService } from './base-redirect.service';

@Injectable({
    providedIn: 'root',
})
export class CategoryRedirectService extends BaseRedirectService {
    private readonly _categoryRedirectRestService = inject(CategoryRedirectRestService);

    public shouldRedirect(queryParams: Params, url: string): Observable<string | null> {
        const queryParamsKeys = Object.keys(queryParams);

        if (queryParamsKeys.length === 1 && queryParams.k) {
            const k = this._getKey(queryParams.k);

            return this._categoryRedirectRestService.get(k).pipe(
                map((categoryMapItem: CategoryMapItem | undefined) => {
                    if (categoryMapItem) {
                        return this._getCategoryUrl(categoryMapItem);
                    }

                    return null;
                }),
            );
        }

        return of(null);
    }

    private _getKey(k: string | string[]): string {
        const categories = (typeof k === 'string' ? k : k[0]).split('_');
        const category = this.getCategoryName(categories[0]) as string;
        const subcategory = this.getCategoryName(categories[1]);

        if (subcategory) {
            return `${category}_${subcategory}`;
        }

        return category;
    }

    private _getCategoryUrl(category: CategoryMapItem): string {
        const url = `/${category.category}`;

        if (category.subcategory) {
            return `${url}/${category.subcategory}`;
        }

        return url;
    }
}
