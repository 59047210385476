import { Routes } from '@angular/router';
import { IframeGuard } from './infrastructure/iframe.guard';
import { RedirectLanguageGuard } from './infrastructure/redirect-language.guard';
import { RedirectUrlGuard } from './infrastructure/redirect-url.guard';
import { IRouteData } from './infrastructure/route-data.interface';
import { SearchPageGuard } from './infrastructure/search-page.guard';
import { CacheTime, WebrockCacheGuard } from './infrastructure/webrock-cache.guard';
import { EbPortalMasterPageComponent } from './shared/components/portal-master-page/portal-master-page.component';

const children = [
    {
        path: 'wydarzenia',
        data: {
            cacheTime: CacheTime.MINUTE_3,
            isQuickSearchHidden: true,
            isSimpleSearchVisible: true,
        },
        canActivate: [SearchPageGuard],
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./search-page/search-page.module').then((m) => m.SearchPageModule),
    },
    {
        path: 'kontakt',
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./contact-page/contact-page.module').then((m) => m.ContactPageModule),
    },
    {
        path: 'centrum-pomocy',
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./faq-page/faq-page.module').then((m) => m.FaqPageModule),
    },
    {
        path: 'newsletter',
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./newsletter-page/newsletter-page.module').then((m) => m.NewsletterPageModule),
    },
    {
        path: 'miejsce',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./place-list-page/place-list-page.module').then((m) => m.PlaceListPageModule),
            },
            {
                path: ':placeSlug',
                loadChildren: () => import('./place-page/place-page.module').then((m) => m.PlacePageModule),
            },
        ],
    },
    {
        path: 'artysta',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./artist-list-page/artist-list-page.module').then((m) => m.ArtistListPageModule),
            },
            {
                path: ':artistSlug',
                loadChildren: () => import('./artist-page/artist-page.module').then((m) => m.ArtistPageModule),
            },
        ],
    },
    {
        path: 'organizatorzy/:slug',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./organizer-page/organizer-page.module').then((m) => m.OrganizerPageModule),
    },
    {
        path: 'lp/:slug',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
    },
    {
        path: 'miasto/:city',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        loadChildren: () =>
            import('./landing-location-page/landing-location-page.module').then((m) => m.LandingLocationPageModule),
    },
    {
        path: '404',
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./error-404-page/error-404-page.module').then((m) => m.Error404PageModule),
    },
    {
        path: '500',
        data: { cacheTime: CacheTime.NONE } as IRouteData,
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./error-500-page/error-500-page.module').then((m) => m.Error500PageModule),
    },
    {
        path: ':category',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./category-page/category-page.module').then((m) => m.CategoryPageModule),
    },
    {
        path: ':category/miasto/:city',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        loadChildren: () =>
            import('./landing-location-page/landing-location-page.module').then((m) => m.LandingLocationPageModule),
    },
    {
        path: ':category/:subcategory',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./subcategory-page/subcategory-page.module').then((m) => m.SubcategoryPageModule),
    },
    {
        path: ':category/:subcategory/miasto/:city',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        loadChildren: () =>
            import('./landing-location-page/landing-location-page.module').then((m) => m.LandingLocationPageModule),
    },
    {
        path: ':category/:subcategory/:title',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./title-page/title-page.module').then((m) => m.TitlePageModule),
    },
    {
        path: '',
        data: {
            isQuickSearchHidden: true,
        },
        component: EbPortalMasterPageComponent,
        loadChildren: () => import('./home-page/home-page.module').then((m) => m.HomePageModule),
    },
    {
        path: '**',
        redirectTo: '',
    },
];

export const routes: Routes = [
    {
        path: 'en',
        canActivate: [WebrockCacheGuard, RedirectUrlGuard, RedirectLanguageGuard, IframeGuard],
        children,
    },
    {
        path: '',
        canActivate: [WebrockCacheGuard, RedirectUrlGuard, RedirectLanguageGuard, IframeGuard],
        children,
    },
];
