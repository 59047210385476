import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HomeRestService } from '../../../../../apps/portal/src/app/rest-api/home-rest.service';
import { IFrame } from '../../../../../apps/portal/src/app/rest-api/models/iframe';
import { IframeService } from '../../../../../apps/portal/src/app/services/iframe.service';
import { DeviceService } from '../../../../../libs/device/src/lib/device.service';
import { CookiesPolicyService } from '../cookies-policy.service';

@Component({
    selector: 'eb-cookies-policy-dialog',
    templateUrl: './cookies-policy-dialog.component.html',
    styleUrls: ['./cookies-policy-dialog.component.less'],
})
export class CookiesPolicyDialogComponent {
    private readonly _cookiesPolicyService = inject(CookiesPolicyService);
    private readonly _homeRestService = inject(HomeRestService);
    private readonly _deviceService = inject(DeviceService);
    private readonly _iframeService = inject(IframeService);
    private _destroyRef = inject(DestroyRef);

    isMobile = true;
    iframe: IFrame | undefined;

    constructor() {
        this._homeRestService.cookiesPolicyShowed().pipe(takeUntilDestroyed()).subscribe();
        this._deviceService.isMobile$.pipe(takeUntilDestroyed()).subscribe((isMobile) => (this.isMobile = isMobile));
        this._iframeService.iframe$.pipe(takeUntilDestroyed()).subscribe((iframe) => {
            this.iframe = iframe;
        });
    }

    protected acceptCookiesPolicy(): void {
        this._homeRestService
            .acceptCookiesPolicy()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => this._cookiesPolicyService.acceptCookiesPolicyIframe());
    }

    protected rejectCookiesPolicy(): void {
        this._homeRestService
            .rejectCookiesPolicy()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => this._cookiesPolicyService.rejectCookiesPolicyIframe());
    }
}
