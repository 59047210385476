import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { InternationalizationService } from '../services/internationalization.service';

@Injectable({
    providedIn: 'root',
})
export class RedirectLanguageGuard implements CanActivate {
    private readonly _i18nService = inject(InternationalizationService);

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isEnRoute = state.url.split('/')[1] === 'en';

        if (!this._i18nService.activeLanguage) {
            this._i18nService.setLanguage(isEnRoute ? 'en' : 'pl');
        } else {
            return this._i18nService.redirectToLanguageVersion(state.url);
        }

        return true;
    }
}
