import { Injectable } from '@angular/core';
import { SLUG_EXCEPTION_MAP } from '../../consts/slug-exception-map.const';
import { IOldSearchQueryParams, ISearchParams } from '../infrastructure/search-page.guard';
import { ILandingPageLocationExistRequest } from '../rest-api/models/landing-page-location-exist-request.model';
import { LandingPageLocationType } from '../rest-api/models/landing-page-location-exit.model';

@Injectable({
    providedIn: 'root',
})
export abstract class BaseRedirectService {
    private _getValues(value: string | null, separator = '_'): string[] {
        return value ? value.split(separator) : [];
    }

    public mapToSearchParams(oldQueryParams: IOldSearchQueryParams): ISearchParams {
        const [categories, subcatogories] = this._getValues(oldQueryParams.k);
        const [provinces, cities] = this._getValues(oldQueryParams.r);

        const category = this._getValues(categories, ',')[0];
        const subcategory = this._getValues(subcatogories, ',')[0];
        const province = this._getValues(provinces, ',')[0];
        const city = this._getValues(cities, ',')[0];

        const searchParams = {
            category: this.getCategoryName(category),
            subcategory: this.getCategoryName(subcategory),
            province,
            city,
        };

        Object.keys(searchParams).forEach(
            (key) =>
                searchParams[key as keyof ISearchParams] === undefined &&
                delete searchParams[key as keyof ISearchParams],
        );

        return searchParams;
    }

    public mapToExistRequest(searchParams: ISearchParams): ILandingPageLocationExistRequest {
        const { city, ...otherParams } = searchParams;
        return { ...otherParams, location: city };
    }

    public getCategoryName(category: string | undefined | null): string | null {
        if (!category) {
            return null;
        }

        return SLUG_EXCEPTION_MAP.has(category) ? (SLUG_EXCEPTION_MAP.get(category) as string) : category;
    }

    private _mapToQueryParams(searchParams: ISearchParams): string {
        const searchString = Object.entries(searchParams)
            .map((searchParam) => searchParam.join('='))
            .join('&');
        return searchString ? `${searchString}` : '';
    }

    public mapToUrl(
        landingPageLocationType: LandingPageLocationType | null,
        searchParams: ISearchParams,
    ): string | null {
        switch (landingPageLocationType) {
            case LandingPageLocationType.NOT_EXIST:
                return `/wydarzenia?${this._mapToQueryParams(searchParams)}`;
            case LandingPageLocationType.SUBCATEGORY:
                return `/${searchParams.category}/${searchParams.subcategory}/miasto/${searchParams.city}`;
            case LandingPageLocationType.CATEGORY:
                return `/${searchParams.category}/miasto/${searchParams.city}`;
            case LandingPageLocationType.CITY:
                return `/miasto/${searchParams.city}`;
            default:
                return null;
        }
    }
}
