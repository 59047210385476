import { isPlatformServer } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { RESPONSE } from '../../consts/request-response.const';
import { RedirectRestService } from '../rest-api/redirect-rest.service';

@Injectable({
    providedIn: 'root',
})
export class RedirectUrlGuard implements CanActivate {
    private readonly _redirectRestService = inject(RedirectRestService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _response = inject(RESPONSE, { optional: true });

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (isPlatformServer(this._platformId)) {
            if (
                state.url == '/404' ||
                state.url == '/500' ||
                state.url == '/' ||
                state.url.indexOf('/wydarzenia') === 0
            ) {
                return of(true);
            }

            return this._redirectRestService.getRedirectUrl(`${state.url.split('?')[0]}`).pipe(
                catchError(() => of(null)),
                map((url: string | null) => {
                    if (url) {
                        this._response?.redirect(
                            HttpStatusCode.MovedPermanently,
                            this._getUrlWithParams(url, next.queryParams),
                        );
                        return false;
                    }

                    return true;
                }),
            );
        }

        return of(true);
    }

    private _getUrlWithParams(url: string, queryParams: Params): string {
        const allowedParams = [
            'eid',
            'partner',
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_term',
            'utm_content',
            'gclid',
            'fbclid',
            'snrai_campaign',
            'city',
            'event',
            'artist',
        ];

        const newUrl = url.replace(/\/+$/, '');
        const params = [] as string[];

        Object.keys(queryParams).map((queryParam: string) => {
            if (allowedParams.indexOf(queryParam) >= 0) {
                params.push(`${queryParam}=${queryParams[queryParam]}`);
            }
        });

        if (params.length) {
            return `${newUrl}?${params.join('&')}`;
        }

        return newUrl;
    }
}
