import { isPlatformServer } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { URL } from 'url';
import { REQUEST, RESPONSE } from '../../consts/request-response.const';
import { CategoryRedirectService } from '../services/category-redirect.service';
import { LandingPageRedirectService } from '../services/landing-page-redirect.service';
import { SearchPageRedirectService } from '../services/search-page-redirect.service';

@Injectable({
    providedIn: 'root',
})
export class SearchPageGuard implements CanActivate {
    private readonly _landingPageRedirectService = inject(LandingPageRedirectService);
    private readonly _categoryRedirectService = inject(CategoryRedirectService);
    private readonly _searchPageRedirectService = inject(SearchPageRedirectService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _response = inject(RESPONSE, { optional: true });
    private readonly _request = inject(REQUEST, { optional: true });

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (isPlatformServer(this._platformId)) {
            return this._categoryRedirectService.shouldRedirect(next.queryParams, state.url).pipe(
                switchMap((redirectUrl: string | null) => {
                    if (redirectUrl) {
                        this._response?.redirect(HttpStatusCode.MovedPermanently, redirectUrl);
                        return of(false);
                    }

                    const oldSearchQueryParams = this._getOldSearchParams(state.url);
                    return this._landingPageRedirectService.shouldRedirect(oldSearchQueryParams).pipe(
                        switchMap((_redirectUrl: string | null) => {
                            if (_redirectUrl) {
                                this._response?.redirect(HttpStatusCode.MovedPermanently, _redirectUrl);
                                return of(false);
                            }

                            return this._searchPageRedirectService.shouldRedirect(oldSearchQueryParams).pipe(
                                map((_redirectUrll: string | null) => {
                                    if (_redirectUrll) {
                                        this._response?.redirect(HttpStatusCode.MovedPermanently, _redirectUrll);
                                        return false;
                                    }

                                    return true;
                                }),
                            );
                        }),
                    );
                }),
            );
        }

        return of(true);
    }

    private _getOldSearchParams(url: string): IOldSearchQueryParams {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const _URL = require('url').URL;
        const baseUrl = `${this._request?.protocol}://${this._request?.hostname}`;
        const newUrl: URL = new _URL(url, baseUrl);

        const k = newUrl.searchParams.get('k') as string;
        const r = newUrl.searchParams.get('r') as string;

        return { k, r };
    }
}

export interface IOldSearchQueryParams {
    k: string;
    r: string;
}

export interface ISearchParams {
    category: string | null;
    subcategory: string | null;
    province: string;
    city: string;
}
