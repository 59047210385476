import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { inject, makeStateKey, PLATFORM_ID, StateKey, TransferState } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';
import { SsrCacheService } from '../services/ssr-cache.service';

/*
  https://ebiletpl.atlassian.net/browse/PE-3303
  TODO: do obserwacji ticket https://github.com/angular/angular/issues/53702
  po przejściu na Angular17 problem ze zbędnymi requestami w przeglądarce:
  - reqeusty wykonane na serwerze powinny zostać odczytane w przeglądarce z cache transferState, a wykonują się ponownie
  ponieważ adres do API jest inny w ssr i na kliencie - w ssr jest prywatny adres, a przegladarka łączy się z zewnętrznym
  na ten moment brak natywnego rozwiązania, więc doszyty interceptor ręcznie wyciągający wartość z transferState
 */

// ponizsze metody i interfejsy poza właściwym interceptorem zostały skopiowane z:
// angular/packages/common/http/src/transfer_cache.ts
// https://github.com/angular/angular/blob/d315e2c4fa178dfbd41bc25259605bb999fa302e/packages/common/http/src/transfer_cache.ts
// brak możliwości importowania - niestety na ten moment to chyba jedyne sensowne rozwiązanie

/**
 * Keys within cached response data structure.
 */
const BODY = 'b';
const HEADERS = 'h';
const STATUS = 's';
const STATUS_TEXT = 'st';
const URL = 'u';
const RESPONSE_TYPE = 'rt';
interface TransferHttpResponse {
    /** body */
    [BODY]: any;
    /** headers */
    [HEADERS]: Record<string, string[]>;
    /** status */
    [STATUS]?: number;
    /** statusText */
    [STATUS_TEXT]?: string;
    /** url */
    [URL]?: string;
    /** responseType */
    [RESPONSE_TYPE]?: HttpRequest<unknown>['responseType'];
}

const makeCacheKey = (request: HttpRequest<any>): StateKey<TransferHttpResponse> => {
    // make the params encoded same as a url so it's easy to identify
    const { params, method, responseType, url } = request;
    const encodedParams = params
        .keys()
        .sort()
        .map((k) => `${k}=${params.getAll(k)}`)
        .join('&');
    const key = method + '.' + responseType + '.' + url + '?' + encodedParams;

    const hash = generateHash(key);

    return makeStateKey(hash);
};

const generateHash = (value: string): string => {
    let hash = 0;

    for (const char of value) {
        hash = (Math.imul(31, hash) + char.charCodeAt(0)) << 0;
    }

    // Force positive number hash.
    // 2147483647 = equivalent of Integer.MAX_VALUE.
    hash += 2147483647 + 1;

    return hash.toString();
};

export const SsrCacheInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
    const platformId = inject(PLATFORM_ID);
    const transferState = inject(TransferState);
    const ssrCacheService = inject(SsrCacheService);

    if (isPlatformBrowser(platformId) && ssrCacheService.cacheEnabled) {
        const cloneRequest = req.clone({
            url: environment.portalApiBaseUrl + req.url,
        });

        const key = makeCacheKey(cloneRequest);
        const data = transferState.get<TransferHttpResponse | undefined>(key, undefined);

        if (data) {
            return of(
                new HttpResponse({
                    body: data[BODY],
                    status: data[STATUS],
                }),
            );
        }
    }

    return next(req);
};
